<template>
    <div class="container">
      <breadcrumb :breadcrumbList="breadcrumbList"></breadcrumb>
      <div class="main-content-title">
        <el-button type="primary" @click="addForm">添加等级</el-button>
        <el-button @click="manageMembers">管理会员成长任务</el-button>
        <el-button @click="setConfig">配置升降级规则</el-button>
      </div>
      <div class="main-content">
        <div class="box_list" v-if="dataList&&dataList.length">
          <div class="box_item" v-for="(item,index) in dataList" :key="index">
            <p class="title">{{index+1}}. {{item.name}}</p>
            <div class="icon">
              <img :src="item.levelIcon" />
            </div>
            <p class="msg">需要成长值{{item.points}}</p>
            <p class="line"></p>
            <div class="bottom_msg">等级权益<sapn>{{ item.benefitCount }}</sapn></div>
            <div class="bottom_msg">等级会员数<sapn>{{item.memberCount}}</sapn></div>
            <p class="bottom_line"></p>
            <div class="bottom_btns">
              <p class="btn" @click="editForm(item)">编辑</p>
              <p class="center_line"></p>
              <p class="btn" @click="removeItem(item)">删除</p>
            </div>
          </div>
        </div>
        <el-empty v-else description="暂无等级数据" />
      </div>
      <ConfigForm ref="ConfigFormRef"></ConfigForm>
    </div>
  </template>
  
  <script lang="js">
  import { defineComponent, reactive, ref } from 'vue'
  import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
  import { breadcrumbList } from './config'
  import { useRouter } from 'vue-router'
  import { popup } from '@/utils/pop-up'
  import { ElMessage } from 'element-plus'
  import ConfigForm from './cpn/configForm.vue'
  import { getPointsMemberList,deletePointsMember} from '@/service/main/member'
  
  export default defineComponent({
    props: {
  
    },
    components: {
      Breadcrumb,
      ConfigForm
    },
    setup() {
      const router = useRouter()
      const ConfigFormRef = ref(null)
      const dataList = ref([])
      const addForm = () =>{
        router.push('/main/member/integral/addForm')
      }
      const editForm = (item) =>{
        router.push({path:'/main/member/integral/editForm',query:{id:item.id}})
      }
      const setConfig = () =>{
        ConfigFormRef.value.open()
      }
      const manageMembers = () =>{
        router.push({path:'/main/member/integral/manageMembers'})
      }

      const getDataList = async() =>{
        let res = await getPointsMemberList({current:-1})
        dataList.value = res.list
      }

      const initPage = async () => {
        getDataList()
      }

      const removeItem = (item) =>{
        popup('确认删除该等级信息吗?', async() => {
          let res = await deletePointsMember(item.id)
          if (res.code==0) {
            ElMessage.success({
              message: '删除成功!',
              type: 'success'
            })
            initPage()
          }
        })
      }
      initPage()
      return {
        breadcrumbList,
        dataList,
        addForm,
        editForm,
        removeItem,
        ConfigFormRef,
        setConfig,
        manageMembers
      }
  
    }
  })
  </script>
  
  <style scoped lang="less">
  .container {
    min-width: 1080px;
  
    .main-content,.main-content-title {
      padding: 20px;
      margin: 20px;
      background-color: #fff;
    }
    .main-content{
        min-height: 80vh;
        padding: 30px 20px;
        .box_list{
          display: flex;
          flex-wrap: wrap;
          margin-left: 15px;
          .box_item{
            width: 220px;
            border: 2px solid #efefef;
            border-radius: 8px;
            box-shadow: 0px 0px 10px #efefef;
            margin-right: 20px;
            margin-bottom: 20px;
            .title{
              text-align: center;
              padding: 15px 0;
              font-size: 16px;
            }
            .icon{
              width: 80px;
              height: 80px;
              margin: 0 auto;
              img{
                width: 100%;
                height: 100%;
              }
            }
            .msg{
              text-align: center;
              font-size: 14px;
              color: #666;
              padding: 20px 0 10px;
            }
            .line{
              width: 88%;
              height: 2px;
              background-color: #efefef;
              margin: 0 auto;
            }
            .bottom_msg{
              width: 88%;
              margin: 0 auto;
              display: flex;
              font-size: 13px;
              color: #999;
              justify-content: space-between;
              margin-top: 12px;
            }
            .bottom_line{
              margin: 12px auto 0;
              height: 1px;
              background-color: #efefef;
            }
            .bottom_btns{
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-size: 14px;
              .btn{
                width: 48%;
                text-align: center;
                height: 38px;
                line-height: 38px;
                color: #999;
                cursor: pointer;
              }
              .center_line{
                height: 38px;
                background-color: #efefef;
                width: 1px;
              }
              .btn:first-child{
                color: #7948ea;
              }
            }
            .bottom_btn{
              font-size: 14px;
              .btn{
                width: 100%;
                text-align: center;
                height: 38px;
                line-height: 38px;
                color: #7948ea;
                cursor: pointer;
              }
            }
          }
        }
    }
  }
  </style>
  
  
  
  
  