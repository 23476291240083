<template>
  <div class="dialog-form">
    <el-dialog v-if="isShow" v-model="isShow" title="配置升降级规则" width="900px" @close="close">
      <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        :rules="rules"
        label-width="120px"
        class="demo-ruleForm"
        label-suffix="："
      >
      <el-form-item label="降级策略" prop="enabled">
        <el-radio-group v-model="ruleForm.enabled">
          <el-radio :label="true">开启降级</el-radio>
          <el-radio :label="false">暂不降级</el-radio>
        </el-radio-group>
      </el-form-item>
      <template v-if="ruleForm.enabled">
        <el-form-item label="降级规则" prop="downgradeRule" style="margin: 0 auto;">
          <el-radio-group v-model="ruleForm.downgradeRule" @change="changeRule">
            <el-radio :label="1">有效期模式
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item style="margin: 0 auto;" v-if="ruleForm.downgradeRule==1">
          <div class="bg">
            <div class="text">
              实时升级，会员入会、等级变更的
              <el-form-item prop="cycle">
                <el-input-number :controls="false" style="width: 100px;" v-model="ruleForm.cycle"></el-input-number>
              </el-form-item>
              天后，根据客户<el-input-number :controls="false" style="width: 100px;" v-model="ruleForm.range"></el-input-number>
              天获得的成长值计算
            </div>
            <el-form-item label="" prop="gradingMode">
              <el-radio-group v-model="ruleForm.gradingMode">
                <el-radio :label="1" style="width: 100%;">若达不到客户所在等级的成长值要求，会员等级降低一级</el-radio>
                <el-radio :label="2" style="width: 100%;">对客户重新定级</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
        </el-form-item>
        <el-form-item style="margin: 0 auto;" v-else>
          <div class="bg">
            <div class="text">
              实时升级，会员入会、等级变更的
              <el-form-item >
                <el-input-number disabled :controls="false" style="width: 100px;"></el-input-number>
              </el-form-item>
              天后，根据客户<el-input-number :controls="false" style="width: 100px;" disabled></el-input-number>
              天获得的成长值计算
            </div>
            <el-form-item label="">
              <el-radio-group disabled>
                <el-radio :label="1" style="width: 100%;">若达不到客户所在等级的成长值要求，会员等级降低一级</el-radio>
                <el-radio :label="2" style="width: 100%;">对客户重新定级</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
        </el-form-item>
        <el-form-item label="" prop="downgradeRule" style="margin: 0 auto;">
          <el-radio-group v-model="ruleForm.downgradeRule" @change="changeRule">
            <el-radio :label="2">定期更新模式
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item style="margin: 0 auto;" v-if="ruleForm.downgradeRule==2">
          <div class="bg">
            <div class="text">
              每
              <el-form-item prop="cycle">
                <el-select style="width: 100px;margin: 0 10px;" v-model="ruleForm.cycle" placeholder="请选择" @change="handleChange">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              的1号升级/降级，根据客户近<el-input-number :controls="false" style="width: 100px;" v-model="ruleForm.range"></el-input-number>个月获得的成长值计算
            </div>
            <div>更新时间为：{{ msg }}</div>
            <el-form-item label="" prop="gradingMode">
              <el-radio-group v-model="ruleForm.gradingMode">
                <el-radio :label="1" style="width: 100%;">若达不到客户所在等级的成长值要求，会员等级降低一级</el-radio>
                <el-radio :label="2" style="width: 100%;">对客户重新定级</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
        </el-form-item>
        <el-form-item style="margin: 0 auto;" v-else>
          <div class="bg">
            <div class="text">
              每
              <el-form-item>
                <el-select style="width: 100px;margin: 0 10px;" disabled placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              的1号升级/降级，根据客户近<el-input-number :controls="false" style="width: 100px;" disabled></el-input-number>个月获得的成长值计算
            </div>
            <div>下次更新时间为：11月1日</div>
            <el-form-item label="">
              <el-radio-group disabled>
                <el-radio :label="1" style="width: 100%;">若达不到客户所在等级的成长值要求，会员等级降低一级</el-radio>
                <el-radio :label="2" style="width: 100%;">对客户重新定级</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
        </el-form-item>
      </template>
    </el-form>
      <template #footer>
        <el-button @click="close">取消</el-button>
        <el-button @click="handleSaveClick(ruleFormRef)" type="primary">保存</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, computed, onUnmounted } from 'vue'
import { savePointsMemberLevelChangeSetting ,getPointsMemberLevelChangeSetting } from '@/service/main/member'
import { ElMessage } from 'element-plus'
export default defineComponent({
  props: {
  },
  components: {
  },
  setup(props, { emit }) {
    const ruleForm = reactive({
      enabled:false,
      gradingMode:1,
      downgradeRule:1,
      cycle:0,
      range:0
    })
    const options = reactive([{value:1,label:'月'},{value:2,label:'季'},{value:3,label:'年'}])
    const rules = {
      
    }
    const ruleFormRef = ref()
    const isShow = ref(false)
    const msg = ref('')
    const open = (data) =>{
      getConfigData()
      isShow.value = true
    }
    const getConfigData = async() =>{
      let res = await getPointsMemberLevelChangeSetting()
      for (const key in res.data) {
        if (Object.prototype.hasOwnProperty.call(res.data, key)) {
          ruleForm[key] = res.data[key];
        }
      }
      if (ruleForm.gradingMode==2) {
        handleChange(ruleForm.cycle)
      }
    }
    const close = () =>{
      isShow.value = false
      ruleFormRef.value.resetFields()
    }
    const changeRule = (e) =>{
      ruleForm.cycle = null
      ruleForm.range = null
      ruleForm.gradingMode = 1
    }
    const handleChange = (e)=>{
      switch (e) {
        case 1:
          msg.value = '下个月一号'
          break;
        case 2:
          msg.value = '1月1日，4月1日，7月1日，10月1日'
          break;
        case 3:
          msg.value = '明年1月1日'
          break;
      
        default:
          break;
      }
    }

    const handleSaveClick = async(formEl) => {
      if (!formEl) return
        await formEl.validate((valid, fields) => {
          if (valid) {
            savePointsMemberLevelChangeSetting(ruleForm).then(res=>{
              ElMessage({
                message:'配置成功',
                type:'success'
              })
            })
            close()
          }
        })
    }

    return {
      handleSaveClick,
      isShow,
      ruleForm,
      ruleFormRef,
      open,
      rules,
      close,
      options,
      changeRule,
      msg,
      handleChange
    }

  }
})
</script>

<style scoped lang="less">
.dialog-form {
  z-index: 999;
  .bg{
    background-color: #F9F5FE;
    padding: 10px 20px;
    width: 90%;
    .text{
      display: flex;
      flex-wrap: wrap;
    }
    .el-input-number{
      margin: 0 10px;
    }
  }
}
</style>
